// Import the necessary Firebase functions
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";  // Import getStorage for Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBhF9SlHl15DKtcrOUIbCssDaFPxrIFlSk",
  authDomain: "codeshark-dev.firebaseapp.com",
  databaseURL: "https://codeshark-dev-default-rtdb.firebaseio.com",
  projectId: "codeshark-dev",
  storageBucket: "codeshark-dev.firebasestorage.app",
  messagingSenderId: "504249013670",
  appId: "1:504249013670:web:366bd15bb311b83f6aab67",
  measurementId: "G-S1HBQNPK6W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);           // Firebase authentication
const db = getDatabase(app);         // Firebase Realtime Database
const analytics = getAnalytics(app); // Firebase analytics (optional)
const storage = getStorage(app);     // Firebase storage (added)

// Export the initialized services for use in other parts of the app
export { auth, db, analytics, storage };
