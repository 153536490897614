import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import './css/QR.css';


export default function QR({ action = () => { console.log("close") } }) {
    return (
        <div className="qr-container">
            <div className="border">
                <h2>KHQR</h2>
                <QRCodeCanvas
                    value="00020101021129450008p2p@hdsb01090001286860216Phillip Bank Plc5204599953038405802KH5915MORM Leapsovann6010Phnom Penh9917001317170716708976304C38A"
                    size={200}
                />
                <button id="close-coffee" onClick={action}>Close</button>
            </div>
        </div>
    );
}
