import React from 'react';
import './css/Footer.css'; 

const Footer = () => {
  return (
    <footer>
      <ul>
        <li><a href='#terms'>Terms</a></li>
        <li><a href='/privacy-policy'>Privacy</a></li>
        <li><a href='#document'>Docs</a></li>
        <li><a href='#contact-support'>Contact CODESHARK Support</a></li>
        <li><a href='#manage-cookies'>Manage cookies</a></li>
        <li><a href='#dont-share'>Do not share my personal information</a></li>
      </ul>
      <p>
        &#169; 2024 <a href='/'>CODESHARK</a> | All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
