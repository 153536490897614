import React, { useState, useEffect } from 'react';
import { db } from './components/firebase';
import { ref, child, get } from 'firebase/database';
import { useParams } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import logo from '../assets/logo.png';
import './css/Course.css';

const courseThumbnails = {
  python: '../assets/course-icons/python-icon.png',
  react: '../assets/course-icons/react-icon.png',
  javascript: '../assets/course-icons/javascript-icon.png',
  cybersecurity: '../assets/course-icons/cybersecurity-icon.png',
};

const Course = () => {
    const { courseType, courseClass } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchCourse = async () => {
        try {
          const courseRef = child(ref(db), `courses/${courseType}/${courseClass}`);
          const snapshot = await get(courseRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const thumbnail = courseThumbnails[courseType] || logo;
            setCourse({ ...data, thumbnail });
            setLoading(false);
          } else {
            setError('Course not found');
            setLoading(false);
          }
        } catch (err) {
          setError('Error fetching course data');
          setLoading(false);
        }
      };
  
      fetchCourse();
    }, [courseType, courseClass]);
  
    return (
      <div className="course-container">
        <Header />
        <main>
          {loading ? (
            <p>Loading course...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="course-content">
              <img src={course.thumbnail || logo} alt={course.name} />
              <h1>{course.name}</h1>
              <p>{course.description}</p>
            </div>
          )}
        </main>
        <Footer />
      </div>
    );
};

export default Course;
