import React from 'react';
import Main from './pages/Main';
import NotFound from './pages/NotFound';
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
// import QR from './pages/components/QR'
import PrivacyPolicy from './pages/PrivacyPolicy';
// import ResetPassword from "./pages/ResetPassword";
import Admin from './pages/Admin';
import Course from './pages/Course';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/course/:courseType/" element={<Course />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/auth" element={<Auth />} />
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
