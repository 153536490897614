import React, { useEffect, useState } from 'react';
import { auth, db } from './components/firebase'; // Ensure 'db' is correctly imported
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { ref, get, set } from 'firebase/database'; // Import Firebase Database functions

import Header from "./components/Header";
import Footer from "./components/Footer";

import gift from '../assets/gift.png';
import logo from '../assets/logo.png';

import './css/Dashboard.css';

const classAvailable = [
  {
    id: 1,
    name: "Basic Web Development",
    thumbnail: logo,
    description: "Learn how to build websites from scratch.",
  },
  {
    id: 2,
    name: "Beginner",
    thumbnail: logo,
    description: "Beginner to advanced courses with clear explanations.",
  },
  {
    id: 3,
    name: "UI/UX Design",
    thumbnail: logo,
    description: "Design cool and user-friendly websites.",
  },
];

export default function Dashboard() {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(''); // State to store the username

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Fetch username from Firebase when the user is authenticated
        const usernameRef = ref(db, `users/${currentUser.uid}/username`);
        get(usernameRef).then((snapshot) => {
          if (snapshot.exists()) {
            setUsername(snapshot.val()); // Set the username from Firebase
          } else {
            setUsername(''); // Handle case when username doesn't exist
          }
        }).catch((error) => {
          console.error("Error fetching username:", error.message);
        });
      } else {
        window.location = '/';
      }
    });

    return unsubscribe;
  }, []);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.location = '/';
      })
      .catch((error) => {
        console.error("Sign-out error:", error.message);
      });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleUpdateProfile = (event) => {
    event.preventDefault();
    if (user) {
      // Update username in Firebase Database
      const userRef = ref(db, `users/${user.uid}/username`);
      set(userRef, username) // Set the new username value in the database
        .then(() => {
          console.log("Username updated successfully");
        })
        .catch((error) => {
          console.error("Error updating username:", error.message);
        });
    }
  };

  return (
    <div className="dashboard-container">
      <Header />
      <div className="dashboard-content">
        <nav>
          <ul>
            <li><a href="#general"><span className="material-icons">home</span> General</a></li>
            <li><a href="#dashboard"><span className="material-icons">dashboard</span> Dashboard</a></li>
            <li><a href="#profile"><span className="material-icons">person</span> Profile</a></li>
          </ul>
        </nav>

        <section id="general">
          <div className="general-content">
            <h1>My Learnings</h1>
            {/* <a href="#" className="how-to-premium">How to get premium?</a> */}
          </div>
          <div className="score">
            <div className="score-title">
              <span className="material-icons">person</span>
              <h3>Score for today</h3>
            </div>
            <div className="score-container">
              <div className='value-container'>
                <img src={gift} alt="gift" className="score-gift" />
                <div className="score-value">
                  <p>Current score</p>
                  <span id="value">00</span>
                </div>
              </div>
              <div className="slider">
                <div className="fill"></div>
              </div>
            </div>
          </div>

          <div className="my-classes">
            <h1>My Classes</h1>
            <div className="class-container">
              {classAvailable.map((classItem) => (
                <div key={classItem.id} className="class-item">
                  <img src={classItem.thumbnail} alt={classItem.name} />
                  <div className="class-info">
                    <h3>{classItem.name}</h3>
                    <p>{classItem.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="profile">
          <h3>My Profile</h3>
          <form className="profile-form" onSubmit={handleUpdateProfile}>
            <input
              type="text"
              placeholder="Enter new name"
              value={username} // Bind input value to the state
              onChange={handleUsernameChange} // Update state on input change
            />
            <button type="submit">Update Profile</button>
          </form>
        </section>
      </div>
      <Footer />
      <button className="logout-button" onClick={handleLogout}>
        <span className="material-icons">exit_to_app</span>
      </button>
    </div>
  );
}
