import React, { useState, useEffect } from 'react';
import Logo from '../assets/logo.png';
import './css/auth.css';
import { auth, db } from './components/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { ref, set, get } from 'firebase/database';


export default function Auth() {
  const [isLogin, setIsLogin] = useState(true);
  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');


  // Check if the user is already logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Redirect to /dashboard if user is logged in
        window.location = '/dashboard';
      }
    });
    return unsubscribe;
  }, []);

  // Handle login form submit
  const handle_login_submit = async (e) => {
    e.preventDefault();
    setError('');

    if (email.includes('@')) {
      // Login with email
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('Login successful:', userCredential.user);
        window.location = '/dashboard';
      } catch (error) {
        setError(error.message);
        console.error('Login error:', error.message);
      }
    } else {
      // Login with username
      const usernameRef = ref(db, 'usernames/' + email);
      const snapshot = await get(usernameRef);
      if (snapshot.exists()) {
        const associatedEmail = snapshot.val();
        try {
          const userCredential = await signInWithEmailAndPassword(auth, associatedEmail, password);
          console.log('Login successful:', userCredential.user);
          window.location = '/dashboard';
        } catch (error) {
          setError(error.message);
          console.error('Login error:', error.message);
        }
      } else {
        setError('Username not found');
      }
    }
  };

  // Handle register form submit
  const handle_register_submit = async (e) => {
    e.preventDefault();
    setError('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Check if the username already exists in the database
    const usernameRef = ref(db, 'usernames/' + username);
    const snapshot = await get(usernameRef);

    if (snapshot.exists()) {
      setError('Username is already taken. Please choose another one.');
      return;
    }

    // Register new user
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Registration successful:', userCredential.user);
      window.location = '/auth#login';

      const userId = userCredential.user.uid;

      // Store user info and username mapping
      await set(ref(db, 'users/' + userId), {
        username: username,
        email: email,
        role: "user",
      });
      await set(ref(db, 'usernames/' + username), email);
    } catch (error) {
      setError(error.message);
      console.error('Registration error:', error.message);
    }
  };

  // Toggle between login and register form
  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const toggleIsAuthenticate = () => {
    setIsAuthenticate(!isAuthenticate);
  };

  // Define handle_request_submit function
const handle_request_submit = async (e) => {
  e.preventDefault();
  setError('');

  // Check if the entered value is an email or username
  if (email.includes('@')) {
    try {
      // Logic to handle sending a reset code to the email address
      // (e.g., Firebase password reset or custom email verification code)

      console.log(`Reset code sent to ${email}`);
    } catch (error) {
      setError(error.message);
      console.error('Error sending reset code:', error.message);
    }
  } else {
    setError('Please enter a valid email address.');
  }
};


  return (
    <div>
      <div className='container'>
        <div className='logo'>
          <a href='/'>
            <img src={Logo} alt='logo' />
          </a>
        </div>
        {isAuthenticate ? (
          isLogin ? (
            <div className='login-container'>
              <h2 className='title'>Login</h2>
              <form className='login-form' onSubmit={handle_login_submit}>
                <label>Email or Username</label>
                <input type='text' placeholder='Email or Username' required value={email} onChange={(e) => setEmail(e.target.value)} />
                <label>Password</label>
                <input type='password' placeholder='Password' required value={password} onChange={(e) => setPassword(e.target.value)} />
                {error && <p className='error'>{error}</p>}
                <button type='submit'>Login</button>
              </form>
              <div className='more-options'>
                <a href='#ForgotPassword' onClick={toggleIsAuthenticate} className='forgot-password'>Forgot Password?</a>
                <p>Don't have an account? <a href='#Register' className='register-link' onClick={toggleForm}>Create new account</a></p>
              </div>
            </div>
          ) : (
            <div className='register-container'>
              <h2 className='title'>Register</h2>
              <form className='login-form' onSubmit={handle_register_submit}>
                <label>Email</label>
                <input type='email' placeholder='Email' required value={email} onChange={(e) => setEmail(e.target.value)} />
                <label>Username</label>
                <input type='text' placeholder='Username' required value={username} onChange={(e) => setUsername(e.target.value)} />
                <label>Password</label>
                <input type='password' placeholder='Password' required value={password} onChange={(e) => setPassword(e.target.value)} />
                <label>Confirm Password</label>
                <input type='password' placeholder='Confirm Password' required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                {error && <p className='error'>{error}</p>}
                <button type='submit'>Register</button>
              </form>
              <div className='more-options'>
                <p>Already have an account? <a href='#Login' className='login-link' onClick={toggleForm}>Login account</a></p>
              </div>
            </div>
          )
        ) : (
          <div className='forgot-password-container'>
            <h2 className='title'>Forgot Password</h2>
            <form className='reset-password-form' onSubmit={handle_request_submit}>
              <label>Email</label>
              <input type='text' placeholder='Email or Username' required value={email} onChange={(e) => setEmail(e.target.value)} />
              {error && <p className='error'>{error}</p>}
              <button type='submit'>Send Request Code</button>
            </form>
            <div className='more-options'>
              <p>Remember password? <a href='#Login' className='login-link' onClick={toggleIsAuthenticate}>Login Account</a></p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
