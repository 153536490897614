import React, { useState, useEffect } from 'react';
import { db } from './components/firebase'; // Assuming db is your Firebase instance
import { set, ref, get, child } from 'firebase/database';

import logo from '../assets/logo.png';
import python_logo from '../assets/course-icons/python-icon.png';
import react_logo from '../assets/course-icons/react-icon.png';
import javascript_logo from '../assets/course-icons/javascript-icon.png';
import cybersecurity_logo from '../assets/course-icons/cybersecurity-icon.png';

import './css/Admin.css';

const courseThumbnails = {
  python: python_logo,
  react: react_logo,
  javascript: javascript_logo,
  cybersecurity: cybersecurity_logo,
};

const FormField = ({ label, id, type, value, onChange, placeholder, children }) => (
  <div>
    <label htmlFor={id}>{label}</label>
    {type === 'textarea' ? (
      <textarea
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required
      />
    ) : type === 'select' ? (
      <select id={id} value={value} onChange={onChange} required>
        {children}
      </select>
    ) : (
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required
      />
    )}
  </div>
);

const Admin = () => {
  const [courseType, setCourseType] = useState('');
  const [courseClass, setCourseClass] = useState('');
  const [courseName, setCourseName] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const snapshot = await get(child(ref(db), 'courses'));
        if (snapshot.exists()) {
          const data = snapshot.val();
          // Convert data object to an array of courses
          const coursesArray = Object.entries(data).flatMap(([courseType, courseClasses]) =>
            Object.entries(courseClasses).map(([courseClass, courseData]) => ({
              type: courseType,
              class: courseClass,
              ...courseData,
            }))
          );
          setCourses(coursesArray);
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!courseType || !courseClass || !courseName || !courseDescription) {
      alert('Please fill in all fields.');
      return;
    }

    const thumbnail = courseThumbnails[courseType] || python_logo; 

    try {
      const courseRef = ref(db, `courses/${courseType}/${courseClass}`);
      await set(courseRef, {
        thumbnail,
        name: courseName,
        description: courseDescription,
      });

      alert('Course uploaded successfully!');
      setCourseType('');
      setCourseClass('');
      setCourseName('');
      setCourseDescription('');
      
      // Update courses list
      setCourses([...courses, { type: courseType, class: courseClass, thumbnail, name: courseName, description: courseDescription }]);
    } catch (error) {
      console.error('Error uploading course:', error);
      alert('Error uploading course. Please try again.');
    }
  };

  return (
    <div className="upload-course-container">
      <h2>Upload New Course</h2>
      <form onSubmit={handleSubmit}>
        <FormField
          label="Course Type"
          id="courseType"
          type="select"
          value={courseType}
          onChange={(e) => setCourseType(e.target.value)}
        >
          <option value="">Select Course Type</option>
          <option value="python">Python</option>
          <option value="javascript">JavaScript</option>
          <option value="cybersecurity">Cybersecurity</option>
          <option value="react">React</option>
        </FormField>

        <FormField
          label="Course Class"
          id="courseClass"
          type="text"
          value={courseClass}
          onChange={(e) => setCourseClass(e.target.value)}
          placeholder="e.g., Intro to JavaScript"
        />

        <FormField
          label="Course Name"
          id="courseName"
          type="text"
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          placeholder="e.g., Learn JavaScript"
        />

        <FormField
          label="Course Description"
          id="courseDescription"
          type="textarea"
          value={courseDescription}
          onChange={(e) => setCourseDescription(e.target.value)}
          placeholder="Description of the course"
        />

        <button type="submit">Upload Course</button>
      </form>

      {/* Courses List */}
      <div className="class-manager">
        <ul className="class-list">
          {courses.length > 0 ? (
            courses.map((course, index) => (
              <li key={index} className="class-item-admin">
                <img src={course.thumbnail || logo} alt="Course Thumbnail" className="class-thumbnail" />
                <div className="class-info">
                  <h3 className="class-title">{course.name}</h3>
                  <p className="class-description">{course.description}</p>
                </div>
                <div className="class-options">
                  <a href="#edit"><span className="material-icons">edit</span></a>
                  <a href="#delete"><span className="material-icons">delete</span></a>
                </div>
              </li>
            ))
          ) : (
            <li className="class-item">No courses available.</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Admin;
