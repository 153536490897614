// import React, { useState, useEffect } from 'react';
import React from 'react';

import Header from "./components/Header"
import Body from "./components/Main-Body"
import Footer from "./components/Footer"

import './css/Main.css';

export default function Main() {
  return (
    <div className='main-container'>
      <Header/>
      <Body/>
      <Footer/>
    </div>
    
  )
}
