import React from 'react';
import './css/Main-Body.css';

import logo from '../../assets/course-icons/logo.png';

import python_logo from '../../assets/course-icons/python-icon.png';
import react_logo from '../../assets/course-icons/react-icon.png';
import javascript_logo from '../../assets/course-icons/javascript-icon.png';
import cybersecurity_logo from '../../assets/course-icons/cybersecurity-icon.png';

const courses = [
  {
    id: 1,
    type: "python",
    name: 'Python',
    logo: python_logo,
    description: 'Learn Python for fun',
  },
  {
    id: 2,
    type: "react",
    name: 'React',
    logo: react_logo,
    description: 'Learn React for fun',
  },
  {
    id: 3,
    type: "javascript",
    name: 'JavaScript',
    logo: javascript_logo,
    description: 'Learn JavaScript for fun',
  },
  {
    id: 4,
    type: "cybersecurity",
    name: 'Cybersecurity',
    logo: cybersecurity_logo,
    description: 'Learn Cybersecurity for fun',
  },
];

const skill_maps = [
  {
    id: 1,
    name: "Basic Web Development",
    logo: logo,
    description: "After learning Basic Web Development, students will be able to make their own site.",
  },
  {
    id: 2,
    name: "Beginner",
    logo: logo,
    description: "This course starts from basic to advanced. Explanations are clear and easy to understand.",
  },
  {
    id: 3,
    name: "UI/UX Design",
    logo: logo,
    description: "In this course, students will learn how to design a cool website.",
  },
];

const Body = () => {
  return (
    <div className='body-container'>
      <section id='home'>
        <h2>Welcome To Our School!</h2>
        <p>Learn To Gain Skills</p>
        <a href='/auth' className="btn">Get Started</a>
      </section>

      <section id='courses'>
        <h2>Courses Available</h2>
        <ul>
          {courses.map(course => (
            <li key={course.id}>
              <a href={`/course/${course.type}`} className="course-link">
                <img src={course.logo} alt={`${course.name} logo`} draggable='false' />
                <p className='course-description'>{course.description}</p>
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section id='skill-map'>
        <h2>Skill Map</h2>
        <ul>
          {skill_maps.map(skill => (
            <li key={skill.id}>
              <div className='skill-logo'>
                <img src={skill.logo} alt={`${skill.name} logo`} draggable='false' />
                <p>{skill.name}</p>
                <p>Beginner Level</p>
              </div>
              <p className='skill-description'>{skill.description}</p>
              <a href={`/learn/${skill.id}`} className='learn-now'>
                Learn Now
              </a>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Body;
