import React from 'react';
import './css/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>This is a sample privacy policy.  You should replace this with your actual privacy policy.</p>
      <p>This policy describes how we collect, use, and share information when you use our service.</p>

      <h2>Information We Collect</h2>
      <p>We may collect various types of information, including:</p>
      <ul>
        <li>Personal information (e.g., name, email address)</li>
        <li>Usage data (e.g., how you use our service)</li>
        <li>Device information (e.g., IP address, browser type)</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Provide and improve our service</li>
        <li>Communicate with you</li>
        <li>Comply with legal obligations</li>
      </ul>

      <h2>How We Share Your Information</h2>
      <p>We may share your information with:</p>
      <ul>
        <li>Service providers</li>
        <li>Legal authorities</li>
      </ul>

      <h2>Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access your information</li>
        <li>Correct your information</li>
        <li>Delete your information</li>
      </ul>

      <h2>Contact Us</h2>
      <p>If you have any questions about this privacy policy, please contact us at [Your Contact Information].</p>
    </div>
  );
};

export default PrivacyPolicy;
