import React, { useEffect, useState } from 'react';
import { auth, db } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, get } from 'firebase/database';
import './css/Header.css'; 

import logo from '../../assets/logo.png';

import QR from './QR';

const Header = () => {
  const [user, setUser] = useState(null);
  const [isShowQr, setQr] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    // Monitor authentication state
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Fetch username from 'users/$uid/username' in the database
        const usernameRef = ref(db, `users/${currentUser.uid}/username`);
        get(usernameRef).then((snapshot) => {
          if (snapshot.exists()) {
            setUsername(snapshot.val()); // Set the username from database
          } else {
            setUsername(''); // Fallback if username is not found
          }
        });
      } else {
        setUser(null);
        setUsername('');
      }
    });

    return unsubscribe; // Clean up listener on component unmount
  }, []);

  const handle_toggle_qr = () => {
    setQr(!isShowQr); // Toggle QR display
  };

  const handleShare = (event) => {
    event.preventDefault(); // Prevent default anchor behavior

    if (navigator.share) {
      navigator.share({
        title: 'Check out this awesome app!',
        text: 'I Found free schools for you!',
        url: window.location.href // Share the current page URL
      })
      .then(() => console.log('Content shared successfully'))
      .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <header>
      <div className='logo-container'>
        <a href="/">
          <img src={logo} alt='logo' />
        </a>
      </div>
      <nav>
        <ul>
          <li><a href='#share' onClick={handleShare}>Share</a></li>
          <li><a href='#coffee' onClick={handle_toggle_qr}>Coffee Me</a></li>
          <li><a href='/#courses'>Courses</a></li>
          <li>
            {user ? (
              <a href='/dashboard' id='username-display'>Welcome! {username}</a>
            ) : (
              <a href='/auth#login' id='login-btn'>Login</a>
            )}
          </li>
        </ul>
      </nav>

      {/* Conditionally render QR component if isShowQr is true */}
      {isShowQr && <QR action={handle_toggle_qr} />}
    </header>
  );
};

export default Header;
